import 'leaflet/dist/leaflet.css'

import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Review } from 'app/components/ServicesVisual/Review'
import { useVocabularyData } from 'app/utils/vocabulary'
import L from 'leaflet'
import React, { memo, useEffect, useState } from 'react'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'

export interface Props {
  googleMapsURL?: string
  image?: ImageProps
  languageCode: string
  latitude?: string
  longitude?: string
  reviewSource?: string
  reviewText?: string
  siteName?: string
  zoom?: string
}

export const Map = memo(function Map({
  googleMapsURL,
  image,
  languageCode,
  latitude,
  longitude,
  reviewSource,
  reviewText,
  siteName,
  zoom,
}: Props) {
  const [markerIcon, setMarkerIcon] = useState(L.divIcon)

  useEffect(() => {
    setMarkerIcon(
      L.divIcon({
        iconSize: [48, 60],
        iconAnchor: [24, 60],
        html: `<svg xmlns="http://www.w3.org/2000/svg" width="46.983" height="60" viewBox="0 0 46.983 60"><path d="M29.491,1C16.571,1,6,11.18,6,23.708a22.233,22.233,0,0,0,4.7,13.573l17.749,23.23a1.4,1.4,0,0,0,1.827.261l.261-.261,17.749-23.23a22.233,22.233,0,0,0,4.7-13.573C52.983,11.18,42.412,1,29.491,1ZM27.664,32.583a9.112,9.112,0,1,1,10.7-10.7,9.2,9.2,0,0,1-7.178,10.7A8.026,8.026,0,0,1,27.664,32.583Z" transform="translate(-6 -1)" /></svg><span>${siteName}</span>`,
      }),
    )
  }, [])

  return (
    <Container row wrap stretch tag="section">
      <LeftSide dial={4}>
        {image ? <Image {...image} /> : null}
        <Review source={reviewSource} text={reviewText} />
      </LeftSide>
      <RightSide>
        {typeof window !== undefined && latitude && longitude ? (
          <MapContainer
            center={[Number(latitude), Number(longitude)]}
            dragging={L.Browser && L.Browser.mobile ? false : true}
            zoom={Number(zoom)}
            scrollWheelZoom={false}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
            />
            <Marker
              icon={markerIcon}
              position={[Number(latitude), Number(longitude)]}
            />
          </MapContainer>
        ) : null}
        {googleMapsURL ? (
          <CTA
            label={useVocabularyData('open-the-map', languageCode)}
            URL={googleMapsURL}
            rel="noreferrer"
            target="_blank"
          />
        ) : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  position: relative;
  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    z-index: 3;
  }
  &:before {
    width: 2.875rem;
    height: 2.875rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    top: -1.4375rem;
  }
  &:after {
    width: 1.125rem;
    height: 1.125rem;
    background: ${({ theme }) => theme.colors.variants.primaryLight1};
    top: -0.5625rem;
  }
`

const LeftSide = styled(FlexBox)`
  width: 50%;
  overflow: hidden;
  padding: 6.25rem 8.125vw 7.75rem;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.4;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding: 5.625rem 1.9375rem;
  }
`

const RightSide = styled.div`
  width: 50%;
  padding-bottom: 27%;
  overflow: hidden;
  position: relative;

  .leaflet-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  .leaflet-marker-icon {
    background: none;
    border: 0;
    cursor: default;
    svg {
      fill: ${({ theme }) => theme.colors.variants.primaryLight1};
    }
    span {
      width: 90px;
      color: ${({ theme }) => theme.colors.variants.neutralDark3};
      font-family: ${({ theme }) => theme.fontFamily.paragraph};
      font-size: 0.6875rem;
      font-weight: 800;
      line-height: 0.875rem;
      position: absolute;
      bottom: -2.75rem;
      left: 50%;
      text-align: center;
      text-transform: uppercase;
      transform: translateX(-50%);
    }
  }
  .leaflet-left {
    top: auto;
    bottom: 0;
    left: 0;

    .leaflet-control {
      display: flex;
      border: 0;
      border-radius: 0;
      margin: 0;
      a {
        width: 3rem;
        height: 3rem;
        line-height: 2.875rem;
        background: ${({ theme }) => theme.colors.variants.neutralLight4};
        color: ${({ theme }) => theme.colors.variants.neutralDark3};
        border: 0;
        border-radius: 0 !important;
        position: relative;
        text-indent: -9999px;
        transition: 0.2s ease-out;
        &.leaflet-control-zoom-in,
        &.leaflet-control-zoom-out {
          &:before {
            content: '';
            width: 18px;
            height: 2px;
            background: ${({ theme }) => theme.colors.variants.neutralDark2};
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
        &.leaflet-control-zoom-in {
          &:after {
            content: '';
            width: 2px;
            height: 18px;
            background: ${({ theme }) => theme.colors.variants.neutralDark2};
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
        &.leaflet-disabled {
          pointer-events: none;
          &:before,
          &:after {
            opacity: 0.2;
          }
        }
        &:hover {
          background: ${({ theme }) => theme.colors.variants.neutralLight3};
        }
      }
    }
  }
  .leaflet-bottom {
    display: none;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding-bottom: 100%;

    .leaflet-left {
      display: none;
    }
  }
`

const CTA = styled(Button)`
  padding-right: 3rem;
  padding-left: 3rem;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;

  @media (max-width: 1023px) {
    left: 0;
  }
`
